.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.skh span {
  transform: scale(1, 1) !important;
  height: 10vh !important;
}
.sk span {
  transform: scale(1, 1) !important;
  height: 100vh;
}
.container {
  max-width: 1200px;
  position: relative;
  width: 100%;
}
@media only screen and (max-width: 767px) {
.flex-xs-column-reverse{
  flex-direction: column-reverse;
}
.dfl_diamond_ring_section {
  margin-bottom: 60px;
}
.bgText {
  display: none;
}
body .create_diamond_section_inner {
  position: static;
}
}
.fs15{
  font-size: 15px;
}
#tab_logic thead th{
  width: 33%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}